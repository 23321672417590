<template>
    <!-- Start Footer -->
    <footer :style="{ '--navbar-color': props.empresa?.cor }" class="relative bg-slate-900 dark:bg-slate-800 mt-24">
        <div class="container relative">
            <div class="grid grid-cols-1">
                <div class="relative py-16">
                    <!-- Subscribe -->
                    <div class="relative w-full">
                        <div
                            class="relative -top-40 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700 overflow-hidden">
                            <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                <div class="md:text-start text-center z-1">
                                    <h3
                                        class="md:text-3xl text-2xl md:leading-normal leading-normal font-medium text-black dark:text-white">
                                        Anuncie Conosco</h3>
                                    <p class="text-slate-400 max-w-xl mx-auto">Você tem imóvel para vender ou alugar?                                        .</p>
                                </div>

                                <div class="subcribe-form z-1">
                                    <form class="relative max-w-lg">
                                        <!-- <input type="email" id="subcribe" name="email"
                                            class="rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-700"
                                            placeholder="Enter your email :"> -->
                                        <RouterLink to="/anuncie" style="    top: -20px;"
                                            class="btn bg-green-600 mb5  text-white rounded-full">Cadastrar conosco</RouterLink>
                                    </form>
                                </div>
                            </div>

                            <div class="absolute -top-5 -start-5">
                                <div
                                    class="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45">
                                </div>
                            </div>

                            <div class="absolute -bottom-5 -end-5">
                                <div
                                    class="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90">
                                </div>
                            </div>
                        </div>

                        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                            <div class="lg:col-span-4 md:col-span-12">
                                <a href="#" class="text-[22px] focus:outline-none">
                                    <img :src="props.empresa?.logo" alt="">
                                </a>
                                <p class="mt-6 text-gray-300">{{props.empresa?.descricao }}</p>

                            </div><!--end col-->

                            <div class="lg:col-span-2 md:col-span-4">
                                <h5 class="tracking-[1px] text-gray-100 font-semibold">Empresa</h5>
                                <ul class="list-none footer-list mt-6">
                                    <li v-for="item in company" :key="item" class="mt-[10px] first:mt-0"><router-link :to="item.link"
                                            class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                                class="uil uil-angle-right-b me-1"></i> {{item.name}}</router-link></li>
                                </ul>
                            </div><!--end col-->

                            <div class="lg:col-span-3 md:col-span-4">
                                <h5 class="tracking-[1px] text-gray-100 font-semibold">Links úteis</h5>
                                <ul class="list-none footer-list mt-6">
                                    <li v-for="item in usefulls" :key="item" class="mt-[10px] first:mt-0"><router-link :to="item.link"
                                            class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                                class="uil uil-angle-right-b me-1"></i> {{item.name}}</router-link></li>
                                </ul>

                                
                            </div><!--end col-->

                            <div class="lg:col-span-3 md:col-span-4">
                                <h5 class="tracking-[1px] text-gray-100 font-semibold">Contatos</h5>


                                <div class="flex mt-6">
                                    <i data-feather="map-pin" class="size-5 text-green-600 me-3"></i>
                                    <div class="">
                                        <h6 class="text-gray-300 mb-2"> {{ props.empresa?.endereco?.rua }}, {{ props.empresa?.endereco?.numero }} <br> {{props.empresa?.endereco?.complemento ? props.empresa.endereco.complemento + ', ' : ''}}
                                            {{ props.empresa?.endereco?.bairro }} <br>
                                            {{ props.empresa?.endereco?.cidade}} / {{ props.empresa?.endereco?.estado }}, {{ props.empresa?.endereco?.cep }} </h6>
                                       
                                    </div>
                                </div>

                                <div class="flex mt-6">
                                    <i data-feather="mail" class="size-5 text-green-600 me-3"></i>
                                    <div class="">
                                        <a href="mailto:contact@example.com"
                                            class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"> {{ props.empresa?.email }} </a>
                                    </div>
                                </div>

                                

                                <div class="flex mt-6">
                                    <i data-feather="phone" class="size-5 text-green-600 me-3"></i>
                                    <div class="">
                                        <a href="tel:+152534-468-854"
                                            class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">{{ props.empresa?.whatsapp }}</a>
                                    </div>
                                </div>

                                <div class="flex mt-6">
                                    <i data-feather="file-text" class="size-5 text-green-600 me-3"></i>
                                    <div class="">
                                        <a href="tel:+152534-468-854"
                                            class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">CRECI {{ props.empresa?.creci }}</a>
                                    </div>
                                </div>
                            </div><!--end col-->
                        </div><!--end grid-->
                    </div>
                    <!-- Subscribe -->
                </div>
            </div>
        </div><!--end container-->

        <div class="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
            <div class="container relative text-center">
                <div class="grid md:grid-cols-2 items-center gap-6">
                    <div class="md:text-start text-center">
                        <p class="mb-0 text-gray-300">©
                            {{ date }} Criado e desenvolvido <i class="mdi mdi-heart text-red-600"></i> por <a
                                href="https://gjdesenvolvimento.com.br/" target="_blank" class="text-reset">Gj Desenvolvimento</a>.
                        </p>
                    </div>

                    <ul class="list-none md:text-end text-center">
                        <li v-for="item in social" :key="item" class="inline me-1"><a :href="item.link" target="_blank"
                                class="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><i
                                   :data-feather="item.icon" :class="item.class"></i></a></li>
                    </ul><!--end icon-->
                </div><!--end grid-->
            </div><!--end container-->
        </div>
    </footer><!--end footer-->
    <!-- End Footer -->
    <a :href="`https://wa.me/${formatarWhatsapp(props?.empresa?.whatsapp)}`" target="_blank" class="whatsapp-float">
        <i class="uil uil-whatsapp"></i>
      </a>
</template>

<script setup>
import { ref } from 'vue';
import { defineProps } from 'vue';
import { RouterLink } from 'vue-router';

const date = ref(new Date().getFullYear())

const formatarWhatsapp = (whatsapp) => {
    return whatsapp?.replace(/\D/g, '');
}
const props = defineProps({
    empresa: Object
})

const social = ref([

    {
        class: 'size-4',
        icon: 'facebook',
        link: props.empresa?.facebook
    },
    {
        class: 'size-4',
        icon: 'instagram',
        link: props.empresa?.instagram
    },
    {
        class: 'size-4',
        icon: 'mail',
        link: `mailto:${props.empresa?.email}`
    },

])

const company = ref([
    {
        link: '/aboutus',
        name: 'Sobre nós'
    },
    {
        link: '/blog',
        name: 'Blog'
    },

])

const usefulls = ref([
    {
        link: '/grid-sidebar',
        name: 'Listagem',
    },
    {
        link: '/anuncie',
        name: 'Anuncie conosco',
    },
    {
        link: '/contact',
        name: 'Contato',
    },
])


</script>

<style lang="scss" scoped>

$color : var(--navbar-color) ;


.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 2px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-float i {
  font-size: 30px;
}

.whatsapp-float:hover {
  background-color: #22bb5b;
}

.hover\:border-green-600:hover {
    border-color: $color !important;
}

.text-green-600 {
    color: $color !important;
}

.hover:bg-green-600 {
    &:hover {
        background-color: $color !important;
    }
}

.bg-green-600 {
    background-color: $color !important;
}


.hover:text-green-600 {
    &:hover {
        color: $color !important;
    }
}

.hover:bg-green-600 {
    &:hover {
        background-color: $color !important;
    }

} 

.hover\:bg-green-600:hover {
    background-color: $color !important;
    color: #fff !important;
}

.border-green-600 {
    border-color: $color !important;
}

.hover:bg-green-700 {
    background-color: $color  !important;
}

.hover\:bg-green-700 {
    background-color: $color  !important;
    color: #fff !important;
}
</style>
