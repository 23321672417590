<template>
  <div>
    <navbar 
      v-if="isHome" 
      :empresa="empresa" 
      :container="'container'"  
      :navLight="'justify-end'"
    />
    <navbar 
      v-else 
      :logoLight="true" 
      :navLight="'nav-light justify-end'" 
      :container="'container'" 
      :empresa="empresa" 

    />

    <router-view :empresa="empresa" />
    <footers :empresa="empresa" />
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import footers from '@/components/footers/footer.vue';
import navbar from '@/components/navbar/navbar.vue';
import { useEmpresaStore } from '@/store/Empresa.js';
import { useHead } from '@vueuse/head';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    footers,
    navbar
  },

  setup() {
    const route = useRoute();

    const empresaStore = useEmpresaStore();

    const empresa = computed(() => {
      return empresaStore?.empresa
    });

    onMounted(() => {
      empresaStore.fetchEmpresa();
    });

    const isHome = computed(() => {
      return route.path === '/' ||  route.path === '/contact' ||  route.name === 'property-detail';
    });

    watch(empresa, (newEmpresa) => { // observe a referência 'empresa' diretamente
  if (newEmpresa) {
    useHead({
      title: newEmpresa.titulo,
      meta: [
        {
          name: 'description',
          content: newEmpresa.descricao,
        },
        {
          property: 'og:title',
          content: newEmpresa.titulo,
        },
        {
          property: 'og:description',
          content: newEmpresa.descricao,
        },
        {
          property: 'keywords',
          content: newEmpresa.palavras_chaves,
        },
      ],
    });
  }
});

    return {
      isHome,
      empresa
    };
  }
}
</script>

