import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/scss/tailwind.scss'
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import '@/assets/libs/@iconscout/unicons/css/line.css'
import router from './router';
import axiosInstance from './plugins/axios';  
import pinia from './store';
import 'vue3-toastify/dist/index.css';
import { createHead } from '@vueuse/head';

const app = createApp(App)
const head = createHead();

app.use(pinia)
app.use(router);
app.use(head);
app.config.globalProperties.$axios = axiosInstance;  
app.mount('#app')


    