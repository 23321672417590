import { defineStore } from 'pinia';
import axios from '@/plugins/axios';

export const useEmpresaStore = defineStore('empresa', {
  state: () => ({
    empresa: null, // Armazena os dados da empresa
    isLoading: false, // Flag para indicar se está carregando
    empresa_id:  process.env.VUE_APP_EMPRESA_ID,
  }),

  actions: {
    // Ação para buscar os dados da empresa
    async fetchEmpresa() {
      try {
        this.isLoading = true; // Ativa a flag de carreg
        const response = await axios.get(`empresa/${this.empresa_id}`);
        this.empresa = response.data; // Atualiza o estado com os dados da empresa
      } catch (error) {
        console.error('Failed to fetch empresa', error);
        throw error;
      } finally {
        this.isLoading = false; // Desativa a flag de carregamento
      }
    },
  },
});
