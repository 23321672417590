import { createWebHashHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import('@/pages/index/index.vue'),
  },
  {
    path: "/grid-sidebar",
    name: "grid-sidebar",
    component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'),
  },

  {
    path: "/property-detail/:slug/:id",
    name: "property-detail",
    component: () => import('@/pages/listing/property-detail/property-detail.vue'),
  },

  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import('@/pages/pages/aboutus.vue'),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import('@/pages/pages/blog/blogs.vue'),
  },
  {
    path: "/blog-detail",
    name: "blog-details",
    component: () => import('@/pages/pages/blog/blog-detail.vue'),
  },
  {
    path: "/blog-detail/:slug/:id",
    name: "blog-detail",
    component: () => import('@/pages/pages/blog/blog-detail.vue'),
  },

  {
    path: "/contact",
    name: "contact",
    component: () => import('@/pages/contact.vue'),
  },
  {
    path: "/anuncie",
    name: "anuncie",
    component: () => import('@/pages/anuncie.vue'),
  },
  {
    path: "/favoritos",
    name: "favoritos",
    component: () => import('@/pages/favoritos.vue'),
  },
  {
    path: "/:404(.*)",
    name: "404",
    component: () => import('@/pages/pages/special-pages/404.vue'),
  },
  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;