<template>
    <nav :style="{ '--navbar-color': props.empresa?.cor }" id="topnav" class="defaultscroll is-sticky" :class="tagline ? 'tagline-height' : ''">
        <div class="relative" :class="container">
            <!-- Logo container-->
            <router-link  class="logo" to="/">
                <span class="inline-block dark:hidden">
                    <img :src="props.empresa?.logo" class="l-dark w-50" width="200" height="24" alt="">
                    <img :src="props.empresa?.logo" class="l-light w-50" width="200" height="24" alt="">
                </span>
                <img :src="props.empresa?.logo" height="24" class="hidden dark:inline-block" alt="">
            </router-link>

            <!-- End Logo container-->

            <!-- Start Mobile Toggle -->
            <div class="menu-extras" @click="handler">
                <div class="menu-item">
                    <a class="navbar-toggle" id="isToggle" :class="toggle === false ? '' : 'open'">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                </div>
            </div>
            <!-- End Mobile Toggle -->

            <!--Login button Start-->
            <ul class="buy-button list-none mb-0">
                <li class="inline mb-0">
                    <router-link to="/favoritos"
                        class="btn btn-icon bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full"><i
                            data-feather="heart" class="size-4 stroke-[3]"></i></router-link>
                </li>
                <li class="sm:inline ps-1 mb-0 hidden">
                    <router-link to="/anuncie"
                        class="btn bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full">Anuncie</router-link>
                </li>
            </ul>
            <!--Login button End-->

            <div id="navigation" :class="toggle === false ? 'none' : 'block'">
                <!-- Navigation Menu-->
                <ul class="navigation-menu" :class="navLight">


                    <li :class="activeIndex === '/home' ? 'active' : ''"><router-link to="/"
                            class="sub-menu-item">Inicial</router-link></li>

                    <li :class="activeIndex === '/aboutus' ? 'active' : ''"><router-link to="/aboutus"
                            class="sub-menu-item">Sobre</router-link></li>

                    <li :class="activeIndex === '/grid-sidebar' ? 'active' : ''"><router-link to="/grid-sidebar"
                                class="sub-menu-item">Imóveis</router-link></li>


                    <li :class="activeIndex === '/blogs' ? 'active' : ''"><router-link to="/blogs"
                            class="sub-menu-item"> Blogs</router-link></li>



                    <li :class="activeIndex === '/contact' ? 'active' : ''"><router-link to="/contact"
                            class="sub-menu-item">Contato</router-link></li>
                </ul><!--end navigation menu-->
            </div><!--end navigation-->
        </div><!--end container-->
    </nav><!--end header-->
    <!-- End Navbar -->
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, computed, } from 'vue';
import feather from 'feather-icons'
// const menu = ref(true);
const current = ref('');
const toggle = ref(false);
const activeIndex = computed(() => window.location.pathname);
// const openMenu = ref('');

const props = defineProps({
    logoLight: {
        type: Boolean,
        required: true
    },
    navLight: {
        type: String,
        required: true
    },
    container: {
        type: String,
        required: true
    },
    tagline: Boolean,
    empresa: Object
})

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', onscroll);
    scrollToTop()
    feather.replace();

});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('scroll', onscroll);
});

function handler() {
    toggle.value = !toggle.value;
}

// function submenu(item) {
//     menu.value = !menu.value;
//     openMenu.value = item;
// }

function handleScroll() {
    const navbar = document.getElementById("topnav");
    if (window.scrollY >= 50) {
        navbar.classList.add("nav-sticky");
    } else {
        navbar.classList.remove("nav-sticky");
    }
}

function onscroll() {
    const sections = document.querySelectorAll("section");
    const navItems = document.querySelectorAll("nav.container.collapse ul li");
    let currentSectionId = ''; // Define a variable to store the current section's id
    sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 60) {
            currentSectionId = section.getAttribute("id");
        }
    });
    navItems.forEach((li) => {
        if (li.classList.contains(currentSectionId)) {
            li.classList.add("active");
        } else {
            li.classList.remove("active");
        }
    });
    current.value = currentSectionId; // Update the current ref
}

function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

</script>

<style lang="scss" scoped>

$color : var(--navbar-color) ;

.text-green-600 {
    color: $color !important;
}

.hover:bg-green-600 {
    &:hover {
        background-color: $color !important;
    }
}

#topnav .navigation-menu > li:hover > .menu-arrow {
    color: $color !important;
}

.bg-green-600 {
    background-color: $color !important;
}

.hover\:bg-green-700:hover {
    background-color: $color !important;
}

#topnav .navigation-menu > li .submenu li a:hover {
    color: $color !important;
}
#topnav .has-submenu.active .submenu li.active > a {
    color: $color !important;
}
#topnav .navigation-menu > li:hover > .menu-arrow {
    color: $color !important;
    border-color: $color !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    color: $color !important;
    border-color: $color !important;
}

.hover:text-green-600 {
    &:hover {
        color: $color !important;
    }
}

.hover:bg-green-600 {
    &:hover {
        background-color: $color !important;
    }

} 

.hover\:bg-green-600:hover {
    background-color: $color !important;
    color: #fff !important;
}

.border-green-600 {
    border-color: $color !important;
}

.hover\:bg-green-700 {
  background-color: $color  !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: $color !important;
}

#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    color: $color !important;
}
</style>